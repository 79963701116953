<label>{{ label }}</label>
<div style="display: flex">
	@for (optionCol of optionColumns(); track $index) {
		<div style="flex: 1">
			@for (option of optionCol; track option.id) {
				<mat-checkbox
					[checked]="(formCtrl.value ?? []).includes(option.id)"
					(change)="onCheckboxChange($event, option.id)">
					{{ option.label }}
				</mat-checkbox>
				<br />
			}
		</div>
	}
</div>
