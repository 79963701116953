import { Component, computed, input } from '@angular/core';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { CommonModule } from '@angular/common';
import { IdLabel } from '../../../../generated-models/api/shared';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { chunk } from 'lodash';

@Component({
	selector: 'checkbox-group-input-form-field',
	standalone: true,
	imports: [CommonModule, MatCheckbox],
	templateUrl: './checkbox-group-input-form-field.component.html',
	styleUrl: './checkbox-group-input-form-field.component.scss'
})
export class CheckboxGroupInputFormFieldComponent extends BaseInputFormFieldComponent {
	public options = input<IdLabel[]>();
	public columns = input<number>(1);
	public readonly = input<boolean>(false);

	public optionColumns = computed(() => {
		const opts = this.options();
		const cols = Math.max(1, this.columns());
		const chunkSize = Math.ceil((opts ?? []).length / cols);
		return chunk(opts, chunkSize);
	});

	constructor() {
		super('A multi-select checkbox group');
	}

	onCheckboxChange($event: MatCheckboxChange, changedId: number) {
		if (!this.readonly()) {
			const selectedValues: number[] = this.formCtrl.value ?? [];
			const nextValues = $event.checked
				? [...selectedValues, changedId]
				: selectedValues.filter(item => item !== changedId);

			nextValues.sort();
			this.formCtrl.setValue(nextValues);
		}
	}
}
